import {fetchCustom404s, fetchGeneralData} from 'server/data'

import {Blocks} from 'blocks/blocks'
import {DP404pageTerm} from 'types/DPTypes'
import {GetStaticProps} from 'next'
import {Hero} from 'blocks/hero/hero'
import {Paragraph} from 'blocks/paragraph'
import React from 'react'
import {useIsHydrated} from 'util/useIsHydrated'
import {useRouter} from 'next/router'

const Custom404: React.FC<{
	custom404s: DP404pageTerm[]
}> = ({custom404s}) => {
	const isHydrated = useIsHydrated()
	const router = useRouter()
	const term = custom404s.find((term) =>
		router.asPath.startsWith(term.path || '')
	)

	if (!isHydrated) return null

	if (term) {
		return (
			<Blocks blocks={term.blocks}>
				{term.blocks.map((block) => (
					<Paragraph {...block} key={block.id} />
				))}
			</Blocks>
		)
	}

	return (
		<Blocks blocks={[]}>
			<Hero title="404 - Page not found" />
		</Blocks>
	)
}
export default Custom404

export const getStaticProps: GetStaticProps = async (context) => {
	const general = await fetchGeneralData(process.env.NEXT_PUBLIC_DRUPAL_LANG)
	const custom404s = await fetchCustom404s(process.env.NEXT_PUBLIC_DRUPAL_LANG)

	return {
		props: {
			general,
			custom404s
		},
		revalidate: 60 * 10
	}
}
